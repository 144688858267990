var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        { attrs: { title: "개최정보" } },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.param.disabled
                    ? _c("c-btn", { attrs: { label: "저장", icon: "save" } })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-datepicker", {
                  attrs: {
                    disabled: _vm.param.disabled,
                    editable: _vm.editable,
                    label: "개최일",
                    name: "col1",
                  },
                  model: {
                    value: _vm.open.col1,
                    callback: function ($$v) {
                      _vm.$set(_vm.open, "col1", $$v)
                    },
                    expression: "open.col1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-text", {
                  attrs: {
                    disabled: _vm.param.disabled,
                    editable: _vm.editable,
                    label: "장소",
                    name: "col2",
                  },
                  model: {
                    value: _vm.open.col2,
                    callback: function ($$v) {
                      _vm.$set(_vm.open, "col2", $$v)
                    },
                    expression: "open.col2",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: _vm.param.disabled,
                    editable: _vm.editable,
                    label: "관련내용",
                    name: "col3",
                  },
                  model: {
                    value: _vm.open.col3,
                    callback: function ($$v) {
                      _vm.$set(_vm.open, "col3", $$v)
                    },
                    expression: "open.col3",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: _vm.param.disabled,
                    editable: _vm.editable,
                    label: "의견",
                    name: "col4",
                  },
                  model: {
                    value: _vm.open.col4,
                    callback: function ($$v) {
                      _vm.$set(_vm.open, "col4", $$v)
                    },
                    expression: "open.col4",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          staticClass: "q-mt-sm",
          attrs: {
            title: "검토필요항목",
            columns: _vm.grid.columns,
            gridHeight: _vm.grid.height,
            data: _vm.grid.data,
            filtering: false,
            usePaging: false,
            columnSetting: false,
            editable: _vm.editable && !_vm.param.disabled,
          },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.param.disabled
                    ? _c("c-btn", { attrs: { label: "저장", icon: "save" } })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "c-table",
        {
          staticClass: "q-mt-sm",
          attrs: {
            title: "추가의견",
            columns: _vm.grid2.columns,
            gridHeight: _vm.grid2.height,
            data: _vm.grid2.data,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            editable: _vm.editable && !_vm.param.disabled,
          },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.param.disabled
                    ? _c("c-btn", {
                        attrs: { label: "추가", icon: "add" },
                        on: { btnClicked: _vm.add },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }