<template>
  <div>
    <c-card title="개최정보">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!param.disabled" label="저장" icon="save" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :disabled="param.disabled"
            :editable="editable"
            label="개최일"
            name="col1"
            v-model="open.col1"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text 
            :disabled="param.disabled"
            :editable="editable"
            label="장소" 
            name="col2"
            v-model="open.col2">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-textarea
            :disabled="param.disabled"
            :editable="editable"
            label="관련내용"
            name="col3" 
            v-model="open.col3">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-textarea 
            :disabled="param.disabled"
            :editable="editable"
            label="의견"
            name="col4" 
            v-model="open.col4">
          </c-textarea>
        </div>
      </template>
    </c-card>

    <c-table
      class="q-mt-sm"
      title="검토필요항목"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :filtering="false"
      :usePaging="false"
      :columnSetting="false"
      :editable="editable&&!param.disabled"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!param.disabled" label="저장" icon="save" />
        </q-btn-group>
      </template>
    </c-table>
    
    <c-table
      class="q-mt-sm"
      title="추가의견"
      :columns="grid2.columns"
      :gridHeight="grid2.height"
      :data="grid2.data"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable&&!param.disabled"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!param.disabled" label="추가" icon="add" @btnClicked="add" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
export default {
  name: 'moc-meeting',
  props: {
    param: {
      type: Object,
      default: () => ({
        disabled: false,
      }),
    },
  },
  data() {
    return {
      editable: true,
      open: {
        col1: '',
        col2: '',
        col3: '',
        col4: '',
      },
      grid: {
        columns: [
          {
            name: 'chngRefWorkNm',
            field: 'chngRefWorkNm',
            label: '구분',
            align: 'center',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'chngRsltYn',
            field: 'chngRsltYn',
            label: '변경/실행여부',
            align: 'center',
            style: 'width:100px',
            type: 'check',
            sortable: true,
          },
          {
            name: 'endSchDt',
            field: 'endSchDt',
            label: '완료예정일',
            align: 'center',
            style: 'width:200px',
            type: 'date',
            sortable: true,
            disableTarget: 'chngRsltYn',
            disableCon: 'O',
          },
          {
            name: 'mgrNm',
            field: 'mgrNm',
            label: '담당자',
            align: 'center',
            style: 'width:150px',
            type: 'user',
            userId: 'mgrId',
            sortable: true,
            disableTarget: 'chngRsltYn',
            disableCon: 'O',
          },
          {
            name: 'reviewEndSchDt',
            field: 'reviewEndSchDt',
            label: '검토완료요구일',
            align: 'center',
            style: 'width:200px',
            type: 'date',
            sortable: true,
            disableTarget: 'chngRsltYn',
            disableCon: 'O',
          },
          {
            name: 'reviewerNm',
            field: 'reviewerNm',
            label: '검토자',
            align: 'center',
            style: 'width:150px',
            type: 'user',
            userId: 'reviewerId',
            sortable: true,
            disableTarget: 'chngRsltYn',
            disableCon: 'O',
          },
        ],
        data: [],
        height: '550px'
      },
      grid2: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '이름',
            align: 'center',
            sortable: true,
          },
          {
            name: 'col',
            field: 'col',
            label: '관련항목',
            align: 'center',
            type: 'select',
            comboItems: [
              { code: '1', codeName: 'PSM Tracking' },
              { code: '2', codeName: '공정위험성평가', disable: true, },
              { code: '3', codeName: '교육/훈련', disable: true, },
              { code: '4', codeName: '안전운전계획', disable: true, },
              { code: '5', codeName: '가동전점검', disable: true, },
              { code: '6', codeName: '비상조치계획', disable: true, },
              { code: '7', codeName: '장외영향평가', disable: true, },
              { code: '8', codeName: '위해관리계획', disable: true, },
              { code: '9', codeName: '설치검사', disable: true, },
            ],
            sortable: true,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '의견',
            align: 'left',
            type: 'text',
            sortable: true,
          },
          // {
          //   name: 'col3',
          //   field: 'col3',
          //   label: '완료여부',
          //   align: 'center',
          //   type: 'check',
          //   sortable: true,
          // },
          // {
          //   name: 'col4',
          //   field: 'col4',
          //   label: '완료일',
          //   align: 'center',
          //   sortable: true,
          // },
        ],
        data: [],
        height: '350px'
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.getReviewItemlist();
      this.getPlusOpinionlist();
    },
    getReviewItemlist() {
      this.grid.data = [
        { chngRefWorkNm: 'PSM Tracking', chngRsltYn: 'O', endSchDt: '2021-06-24', mgrNm: '홍길동', mgrId: '1', reviewEndSchDt: '', reviewerNm: '', reviewerId: '' },
        { chngRefWorkNm: '공정위험성평가', chngRsltYn: 'X', endSchDt: '', mgrNm: '', mgrId: '' },
        { chngRefWorkNm: '교육/훈련', chngRsltYn: 'X', endSchDt: '', mgrNm: '', mgrId: '' },
        { chngRefWorkNm: '안전운전계획', chngRsltYn: 'X', endSchDt: '', mgrNm: '', mgrId: '' },
        { chngRefWorkNm: '가동전점검', chngRsltYn: 'X', endSchDt: '', mgrNm: '', mgrId: '' },
        { chngRefWorkNm: '비상조치계획', chngRsltYn: 'X', endSchDt: '', mgrNm: '', mgrId: '' },
        { chngRefWorkNm: '장외영향평가', chngRsltYn: 'X', endSchDt: '', mgrNm: '', mgrId: '' },
        { chngRefWorkNm: '위해관리계획', chngRsltYn: 'X', endSchDt: '', mgrNm: '', mgrId: '' },
        { chngRefWorkNm: '설치검사', chngRsltYn: 'X', endSchDt: '', mgrNm: '', mgrId: '' },
      ]
    },
    getPlusOpinionlist() {
      this.grid2.data = [
        { col1: '홍씨', col2: 'Ti 재질 검토 _ PH 2~9 60℃ 이하 운전 권장', col3: 'O', col4: '2021-06-24', col: '1' },
      ]
    },
    add() {
      this.grid2.data.splice(0, 0, {
        col1: '최씨',
        col2: '',
        col3: 'X',
        col4: '',
        col: '선택하세요',
        colx: null,
      })
    },
  }
};
</script>
